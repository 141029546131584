// import ChartBarIcon from "@heroicons/react/24/solid/ChartBarIcon";
import ArchiveBoxIcon from "@heroicons/react/24/outline/ChartBarSquareIcon";
import GifIcon from "@heroicons/react/24/solid/GiftIcon";
import UsersIcon from "@heroicons/react/24/solid/UserIcon";
import { Leaderboard } from "@mui/icons-material";
import { SvgIcon } from "@mui/material";

export const items = [
  {
    title: "Overview",
    path: "/",
    icon: (
      <SvgIcon fontSize="small">
        <ArchiveBoxIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Rewards",
    path: "/rewards",
    icon: (
      <SvgIcon fontSize="small">
        <GifIcon />
      </SvgIcon>
    ),
  },
  {
    title: "User Stats",
    path: "/user",
    icon: (
      <SvgIcon fontSize="small">
        <UsersIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Leaderboard",
    path: "/leaderboard",
    icon: (
      <SvgIcon fontSize="small">
        <Leaderboard />
      </SvgIcon>
    ),
  },
];
