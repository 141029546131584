import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyByzqT2TlkRgS9FWEV9jVmlwM3q5WYf8wc",
  authDomain: "auth.coden.io",
  projectId: "cdn-service-51a7f",
  storageBucket: "cdn-service-51a7f.appspot.com",
  messagingSenderId: "938225427561",
  appId: "1:938225427561:web:06db549101ddbb15e4eef8",
  measurementId: "G-SWX6LS0TFS",
};

const app = initializeApp(firebaseConfig);
export { app };
