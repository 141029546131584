import React from "react";
import { Typography, Grid, Stack, IconButton, Tooltip } from "@mui/material";
import StatsCard from "./Common/StatsCard";
import DetailsCard from "./Common/DetailsCard";
import LogoutIcon from "@mui/icons-material/Logout";

function ProfileDetails({ userData }) {
  function logoutUser() {
    localStorage.removeItem("authToken");
    window.location.href = "/";
  }

  return (
    <div>
      <Grid sx={{ mt: 6, p: 2 }}>
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ color: "white", textAlign: "center" }} variant="h2">
            {userData.name} 👋
          </Typography>

          <Tooltip title="Logout">
            <div
              style={{
                background: "#575757",
                borderRadius: "99999px",
                padding: "1px",
              }}
            >
              <IconButton
                onClick={logoutUser}
                sx={{ fontSize: "18px", color: "white" }}
              >
                Log Out{" "}
                <LogoutIcon style={{ marginLeft: "8px" }} fontSize="18px" />
              </IconButton>
            </div>
          </Tooltip>
        </Stack>

        <Stack sx={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Typography
            sx={{ color: "white", textAlign: "center", my: 4 }}
            variant="h5"
          >
            Wallet Address: {userData.walletAddress}
          </Typography>
        </Stack>
      </Grid>
      <Grid container spacing={4} sx={{ mb: 6, p: 2 }}>
        <Grid item md={4}>
          <StatsCard
            title="Total Runtime"
            value={`${Math.floor(userData?.totalActiveTime / 60)} Hrs`}
          />
        </Grid>
        <Grid item md={4}>
          <StatsCard title="Total Rewards" value={userData?.totalRewards} />
        </Grid>
        <Grid item md={4}>
          <StatsCard
            title="Total Rewards Claimed"
            value={userData?.totalRewardsClaimed}
          />
        </Grid>

        <Grid item md={4}>
          <DetailsCard title="Referal Code" value={userData?.referralCode} />
        </Grid>

        <Grid item md={4}>
          <DetailsCard
            title="Total Users Refered"
            value={userData?.referredUsers.length}
          />
        </Grid>

        <Grid item md={4}>
          <DetailsCard
            title="Total Referal Rewards "
            value={userData?.referralRewards}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default ProfileDetails;
