import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import StatsCard from "./Common/StatsCard";
import axios from "axios";
import { ethers } from "ethers";
// import rewardAbi from "./abi/abi.json";
import setting from "./settings.json";
import { useSigner, useAddress } from "@thirdweb-dev/react";
import { toast } from "react-toastify";

function Rewards() {
  const BASE_URL = "https://api.coden.io";

  const address = useAddress();

  const signer = useSigner();

  const [stats, setStats] = useState(null);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(null);
  const [walletAddress, setWalletAddress] = useState(null);

  const [transactionData, setTransactionData] = useState(null);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const claimRewards = async () => {
    let toastId = null;
    try {
      const abi = [
        "function transferTokens(address to, uint256 amount, string nonce, uint256 expiry, bytes signature) public",
      ];

      const rewardContract = new ethers.Contract(
        setting.REWARD_CONTRACT,
        abi,
        signer
      );

      toastId = toast.loading(`Transaction Processing`);

      const claimTransaction = await rewardContract.transferTokens(
        address,
        ethers.utils.parseUnits(transactionData.amount.toString(), 18),
        transactionData.nonce,
        transactionData.expiry,
        transactionData.signature
      );

      const txn = await claimTransaction.wait();

      console.log(txn, "txn");

      toast.update(toastId, {
        render: `Transaction Successful ${txn.transactionHash}`,
        type: "success",
        isLoading: false,
        autoClose: 3000,
        draggable: true,
      });
      handleClose();
      fetchStatsData(address);
    } catch (error) {
      let err = "";

      if (error instanceof Error) {
        if (ethers.errors.ACTION_REJECTED === error.code) {
          err = "Transaction rejected by the user";
        } else if (ethers.errors.UNPREDICTABLE_GAS_LIMIT === error.code) {
          err = error.error.message;
        }
        console.log(error.message);
      } else {
        console.error("An unknown error occurred while sending native token");
        err = "An unknown error occurred";
      }

      toast.update(toastId, {
        render: `${err}`,
        type: "error",
        isLoading: false,
        autoClose: 3000,
        draggable: true,
      });
      handleClose();
    }
  };

  async function getRewardsSignature() {
    const data = {
      toAddress: address,
    };

    try {
      const response = await axios.post(BASE_URL + "/rewards/claim", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      setTransactionData(response.data);
      handleClickOpen();
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
    }
  }

  const fetchUserData = async () => {
    const token = localStorage.getItem("authToken");
    setLoading(true);
    if (!token) {
      setLoading(false);
      setIsLoggedIn(false);
      setErrorMsg("Login to access rewards");
      return;
    }

    try {
      const response = await axios.get(`${BASE_URL}/user`, {
        headers: { Authorization: token },
      });
      setWalletAddress(response.data.walletAddress);
      setIsLoggedIn(true);
      setLoading(false);
      console.log("User data fetched:", response.data);
    } catch (fetchError) {
      setIsLoggedIn(false);
      setErrorMsg("Login to access rewards");
    } finally {
      setLoading(false);
    }
  };

  async function fetchStatsData(address) {
    try {
      const response = await axios.get(`${BASE_URL}/stats/${address}`);
      console.log(response.data);
      if (response.data.status === 200) {
        setStats(response.data.data);
      } else {
        setError(true);
        setErrorMsg(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    fetchUserData();
    if (address) {
      fetchStatsData(address);
    }
  }, [address]);

  return (
    <>
      {loading ? (
        <Grid
          container
          spacing={3}
          sx={{
            mt: 10,
            mb: 3,
            p: 2,
            height: "80%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress sx={{ color: "white" }} />
        </Grid>
      ) : (
        <div className="bg-img">
          <div className="content">
            {address && address === walletAddress && stats && !error ? (
              <>
                <Grid sx={{ mt: 6, p: 2 }}>
                  <Typography
                    sx={{ color: "white", textAlign: "center" }}
                    variant="h5"
                  >
                    User Stats
                  </Typography>
                </Grid>
                <Grid container spacing={4} sx={{ mb: 6, p: 2 }}>
                  <Grid item md={4}>
                    <StatsCard
                      title="Total Runtime"
                      value={stats?.totalActiveTime}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <StatsCard
                      title="Total Rewards"
                      value={stats?.totalRewards}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <StatsCard
                      title="Total Rewards Claimed"
                      value={stats?.totalRewardsClaimed}
                    />
                  </Grid>
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    sx={{
                      color: "black",
                      background: "#ffe84c",
                      borderRadius: "8px",
                      "&:hover": {
                        backgroundColor: "#ffe84c",
                      },
                    }}
                    // disabled={
                    //   stats?.totalRewards - stats?.totalRewardsClaimed <= 0
                    // }
                    disabled={true}
                    onClick={getRewardsSignature}
                  >
                    {/* {stats?.totalRewards - stats?.totalRewardsClaimed > 0
                    ? ` Claim ${
                        stats?.totalRewards - stats?.totalRewardsClaimed
                      } Rewards`
                    : `No Rewards to claim`} */}
                    Claim Enable soon
                  </Button>
                </Grid>{" "}
              </>
            ) : address && address !== walletAddress ? (
              <Grid sx={{ mt: 20, p: 2 }}>
                <Typography
                  sx={{ color: "white", textAlign: "center" }}
                  variant="h2"
                >
                  {errorMsg ? errorMsg : "Incorrect wallet connected"}
                </Typography>
              </Grid>
            ) : (
              <Grid sx={{ mt: 20, p: 2 }}>
                <Typography
                  sx={{ color: "white", textAlign: "center" }}
                  variant="h2"
                >
                  {errorMsg ? errorMsg : "Connect wallet to claim rewards"}
                </Typography>
              </Grid>
            )}
          </div>
        </div>
      )}

      <React.Fragment>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Claim Rewards"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Your rewards will be send to your connected wallet. Make sure you
              have neccessary gas fees
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={claimRewards} autoFocus>
              Continue
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </>
  );
}

export default Rewards;
