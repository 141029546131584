import React, { useState, useEffect } from "react";
import Bars3Icon from "@heroicons/react/24/solid/Bars3Icon";
import { Box, IconButton, Stack, SvgIcon, useMediaQuery } from "@mui/material";
import axios from "axios";
import { ConnectWallet } from "@thirdweb-dev/react";

const SIDE_NAV_WIDTH = 280;
const TOP_NAV_HEIGHT = 70;

export const TopNav = (props) => {
  const BASE_URL = "https://api.coden.io";
  const { onNavOpen } = props;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const fetchUserData = async () => {
    const token = localStorage.getItem("authToken");

    if (!token) {
      setIsLoggedIn(false);
      return;
    }

    try {
      const response = await axios.get(`${BASE_URL}/user`, {
        headers: { Authorization: token },
      });
      setIsLoggedIn(true);
      console.log("User data fetched:", response.data);
    } catch (fetchError) {
      setIsLoggedIn(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <>
      <Box
        component="header"
        sx={{
          backdropFilter: "blur(8px)",
          backgroundColor: "#151517ad",
          position: "sticky",
          // width: "100%",
          left: {
            lg: `${SIDE_NAV_WIDTH}px`,
          },
          top: 0,
          width: {
            lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`,
          },
          zIndex: (theme) => theme.zIndex.appBar,
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{
            minHeight: TOP_NAV_HEIGHT,
            px: 2,
          }}
        >
          <Stack alignItems="center" direction="row" spacing={2}>
            {!lgUp && (
              <IconButton onClick={onNavOpen}>
                <SvgIcon fontSize="small">
                  <Bars3Icon />
                </SvgIcon>
              </IconButton>
            )}
          </Stack>
          <Stack alignItems="center" direction="row" spacing={2}>
            {isLoggedIn && (
              <IconButton>
                <ConnectWallet
                  style={{ background: "#ffe84c", color: "black" }}
                />
              </IconButton>
            )}
          </Stack>
        </Stack>
      </Box>
    </>
  );
};
