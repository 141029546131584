import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Button,
  Divider,
  FormLabel,
  TextField,
  FormControl,
  Card,
  CircularProgress,
  Box,
} from "@mui/material";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  // signInWithEmailAndPassword,
  // createUserWithEmailAndPassword,
  // sendEmailVerification,
} from "firebase/auth";
import { GoogleIcon } from "./Common/googleIcons";
import axios from "axios";
import { toast } from "react-toastify";

import { app } from "./Firebase/config";
import ProfileDetails from "./ProfileDetails";
import { useLocation } from "react-router-dom";

function Profile() {
  const BASE_URL = "https://api.coden.io";

  const searchParams = new URLSearchParams(useLocation().search);
  const referralCodeFromQuery = searchParams.get("referal");
  console.log(referralCodeFromQuery, "asda");

  const [walletAddress, setWalletAddress] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchUserData = async () => {
    const token = localStorage.getItem("authToken");

    if (!token) {
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get(`${BASE_URL}/user`, {
        headers: { Authorization: token },
      });
      setUserData(response.data);
      console.log("User data fetched:", response.data);
    } catch (fetchError) {
      console.error("Failed to fetch user data:", fetchError.response?.data);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (referralCodeFromQuery) {
      setReferralCode(referralCodeFromQuery);
    }
    fetchUserData();
  }, [referralCodeFromQuery]);

  const handleSignUp = async () => {
    let toastId = null;

    const auth = getAuth(app);
    const provider = new GoogleAuthProvider();

    toastId = toast.loading(`Processing`);

    try {
      if (!walletAddress) {
        toast.update(toastId, {
          render: `Enter Wallet Address`,
          type: "error",
          isLoading: false,
          autoClose: 3000,
          draggable: true,
        });
        return;
      }

      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const googleId = user.uid;
      const email = user.email;
      const name = user.displayName;

      try {
        const signupResponse = await axios.post(`${BASE_URL}/user/signup`, {
          googleId,
          email,
          name,
          walletAddress,
          referralCode,
        });

        console.log("Signup successful:", signupResponse.data);
        const token = signupResponse.data.token;
        localStorage.setItem("authToken", token);
        await fetchUserData();

        toast.update(toastId, {
          render: `Signup successful`,
          type: "success",
          isLoading: false,
          autoClose: 3000,
          draggable: true,
        });
      } catch (signupError) {
        console.error("Signup failed:", signupError.response.data);
        toast.update(toastId, {
          render: `Signup failed: ${signupError.response.data.message}`,
          type: "error",
          isLoading: false,
          autoClose: 3000,
          draggable: true,
        });
      }
    } catch (firebaseError) {
      toast.update(toastId, {
        render: `Google sign-in failed`,
        type: "error",
        isLoading: false,
        autoClose: 3000,
        draggable: true,
      });
      console.error("Google sign-in failed:", firebaseError);
    }
  };

  const handleSignIn = async () => {
    let toastId = null;

    const auth = getAuth(app);
    const provider = new GoogleAuthProvider();

    toastId = toast.loading(`Processing`);

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const googleId = user.uid;

      try {
        const loginResponse = await axios.post(`${BASE_URL}/user/login`, {
          googleId,
        });

        console.log("Login successful:", loginResponse.data);

        const token = loginResponse.data.token;
        localStorage.setItem("authToken", token);
        await fetchUserData();

        toast.update(toastId, {
          render: `Login successful`,
          type: "success",
          isLoading: false,
          autoClose: 3000,
          draggable: true,
        });
      } catch (loginError) {
        console.error("Login failed:", loginError.response.data);
        toast.update(toastId, {
          render: `Login failed: ${loginError.response.data.message}`,
          type: "error",
          isLoading: false,
          autoClose: 3000,
          draggable: true,
        });
      }
    } catch (firebaseError) {
      toast.update(toastId, {
        render: `Google sign-in failed`,
        type: "error",
        isLoading: false,
        autoClose: 3000,
        draggable: true,
      });
      console.error("Google sign-in failed:", firebaseError);
    }
  };

  return (
    <div className="bg-img">
      <div className="content">
        {loading ? (
          <Grid
            container
            spacing={3}
            sx={{
              mt: 10,
              mb: 3,
              p: 2,
              height: "80%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress sx={{ color: "white" }} />
          </Grid>
        ) : userData ? (
          <>
            <ProfileDetails userData={userData} />
          </>
        ) : (
          <Grid
            container
            spacing={3}
            sx={{
              mt: 10,
              mb: 3,
              p: 2,
              height: "80%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item md={4}>
              <Card
                variant="outlined "
                sx={{
                  background: "#000000a3",
                  boxShadow: "#ffe84ccf 0px 0px 6px 2px",
                  p: 2,
                }}
              >
                <Typography
                  component="h1"
                  variant="h5"
                  sx={{
                    width: "100%",
                    color: "white",
                    textAlign: "center",
                    my: 2,
                  }}
                >
                  Register/Login
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    gap: 2,
                  }}
                >
                  <FormControl>
                    <FormLabel
                      htmlFor="walletAddress"
                      sx={{ mb: 2, color: "white" }}
                    >
                      Wallet Address*
                    </FormLabel>
                    <TextField
                      id="walletAddress"
                      type="text"
                      name="walletAddress"
                      placeholder="0x35a8******0"
                      autoComplete="off"
                      autoFocus
                      required
                      fullWidth
                      variant="outlined"
                      onChange={(e) => setWalletAddress(e.target.value)}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          color: "#9a9a9a",
                          "& fieldset": {
                            borderColor: "#9a9a9a",
                          },
                          "&:hover fieldset": {
                            borderColor: "white",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "white",
                          },
                        },
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      htmlFor="referalCode"
                      sx={{ mb: 2, color: "white" }}
                    >
                      Referal Code
                    </FormLabel>
                    <TextField
                      name="referalCode"
                      placeholder="zo8q9t"
                      type="text"
                      id="referalCode"
                      autoComplete="off"
                      autoFocus
                      required
                      fullWidth
                      onChange={(e) => setReferralCode(e.target.value)}
                      variant="outlined"
                      value={referralCode}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          color: "#9a9a9a", // Text color
                          "& fieldset": {
                            borderColor: "#9a9a9a", // Border color
                          },
                          "&:hover fieldset": {
                            borderColor: "white !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "white !important",
                          },
                        },
                      }}
                    />
                  </FormControl>

                  <Button
                    fullWidth
                    variant="contained"
                    startIcon={<GoogleIcon />}
                    onClick={handleSignUp}
                    sx={{
                      background: "transparent",
                      border: "1px solid #ffe84c9",
                      "&:hover": {
                        backgroundColor: "transparent",
                        borderColor: "#ffe84c",
                      },
                    }}
                  >
                    Sign Up with Google
                  </Button>
                </Box>
                <Divider sx={{ color: "white", my: 4 }}>or</Divider>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Button
                    fullWidth
                    variant="outlined"
                    startIcon={<GoogleIcon />}
                    onClick={handleSignIn}
                    sx={{
                      color: "black",
                      background: "#ffe84c",
                      "&:hover": {
                        backgroundColor: "#ffe84c",
                        borderColor: "#ffe84c",
                      },
                    }}
                  >
                    Sign In with Google
                  </Button>
                </Box>
              </Card>
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
}

export default Profile;
